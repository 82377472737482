<template>
  <SubNav
    class="subnav-position"
    :selected="'rentrolls'"
    :buildingId="buildingId"
  />
  <main>
    <form @submit.prevent="handleSubmit" class="shadow edit-form">
      <div class="float-end">
        <router-link
          :to="{ name: 'RentRoll', params: { buildingId: buildingId } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <div class="form-outline mb-4">
        <label>Tenant Company Name</label>
        <input type="text" required v-model="tenant" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Suite</label>
        <input type="text" required v-model="suite" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Usqft</label>
        <input
          type="number"
          set="1"
          required
          v-model="usqft"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>$/Usqft</label>
        {{ usqftPrice }}
      </div>
      <div class="form-outline mb-4">
        <label>rsqft</label>
        {{ rsqft }}
      </div>
      <div class="form-outline mb-4">
        <label>$/Rsqft</label>
        {{ rsqftPrice }}
      </div>
      <div class="form-outline mb-4">
        <label>Monthly Rent</label>
        <input
          type="number"
          step="0.01"
          required
          v-model="rent"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Annual Rent</label>
        {{ rentAnnual }}
      </div>
      <div class="form-outline mb-4">
        <label>Other $</label>
        <input
          type="number"
          step="0.01"
          required
          v-model="otherCosts"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Expiration Date</label>
        <input
          type="date"
          required
          v-model="expirationDate"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Escalator (as %, e.g. 5.5 == 5.5%)</label>
        <input
          type="number"
          step="0.01"
          required
          v-model="escalator"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Escalator Date</label>
        <input type="date" v-model="escalatorDate" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Notice Date</label>
        <input type="date" v-model="noticeDate" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Notes</label>
        <input type="text" v-model="notes" class="form-control" />
      </div>
      <div v-if="!isPending" class="row text-center">
        <div v-if="!errorForm" class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div v-else class="col">
          <button class="btn btn-secondary" disabled>Save</button>
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'RentRoll',
              params: { buildingId: buildingId }
            }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import { ref, computed } from "@vue/reactivity";
import SubNav from "@/components/navigation/SubNav.vue";
import useCollection from "@/composables/useCollection";
import { timestamp } from "@/firebase/config";
import { useRouter } from "vue-router";

export default {
  props: ["buildingId"],
  components: { SubNav },
  setup(props) {
    const isPending = ref(false);
    const errorForm = ref(null);
    const tenant = ref(null);
    const suite = ref(null);
    const usqft = ref(0);
    const rent = ref(0);
    const otherCosts = ref(0);
    const expirationDate = ref(null);
    const escalator = ref(0.0);
    const escalatorDate = ref("");
    const noticeDate = ref("");
    const notes = ref("");
    const router = useRouter();
    const { error: errorAdd, addDoc } = useCollection(
      `rentrolls/${props.buildingId}/leaseList/`
    );

    const rentAnnual = computed(() => {
      if (rent.value) {
        return rent.value * 12;
      } else {
        return 0;
      }
    });

    const usqftPrice = computed(() => {
      if (rentAnnual.value && usqft.value && usqft.value > 0) {
        return rentAnnual.value / usqft.value;
      } else {
        return 0;
      }
    });

    const rsqft = computed(() => {
      if (usqft.value) {
        return usqft.value * 1.11;
      } else {
        return 0;
      }
    });

    const rsqftPrice = computed(() => {
      if (rentAnnual.value && usqftPrice && rsqft.value > 0) {
        return rentAnnual.value / rsqft.value;
      } else {
        return 0;
      }
    });

    const handleSubmit = async () => {
      try {
        errorForm.value = null;
        isPending.value = true;
        await addDoc({
          tenant: tenant.value,
          suite: suite.value,
          usqft: usqft.value,
          usqftPrice: usqftPrice.value,
          rsqft: rsqft.value,
          rsqftPrice: rsqftPrice.value,
          rent: rent.value,
          rentAnnual: rentAnnual.value,
          otherCosts: otherCosts.value,
          expirationDate: expirationDate.value,
          escalator: escalator.value,
          escalatorDate: escalatorDate.value,
          noticeDate: noticeDate.value,
          notes: notes.value,
          createdAt: timestamp()
        });
        isPending.value = false;
        if (errorAdd.value) {
          errorForm.value = errorAdd.value;
        } else {
          router.push({
            name: "RentRoll",
            params: { buildingId: props.buildingId }
          });
        }
      } catch (err) {
        errorForm.value = err.message;
      }
    };

    return {
      tenant,
      suite,
      usqft,
      rent,
      otherCosts,
      expirationDate,
      escalator,
      escalatorDate,
      noticeDate,
      notes,
      usqftPrice,
      rsqft,
      rsqftPrice,
      rentAnnual,
      isPending,
      errorForm,
      handleSubmit
    };
  }
};
</script>

<style>
</style>